import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/ichwan/Documents/repos/new-personal-site/node_modules/@pauliescanlon/gatsby-theme-terminal/src/layouts/PageLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Heading = makeShortcode("Heading");
const Link = makeShortcode("Link");
const Divider = makeShortcode("Divider");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Contacts`}</h1>
    <Heading variant='styles.h3' sx={{
      mb: 0
    }} mdxType="Heading">Email</Heading>
    <Link href='mailto:ipalongengi@gmail.com' variant='styles.a' mdxType="Link">ipalongengi@gmail.com</Link>
    <Divider mdxType="Divider" />
    <Heading variant='styles.h3' sx={{
      mb: 0
    }} mdxType="Heading">LinkedIn</Heading>
    <Link href='https://www.linkedin.com/in/ipalongengi' variant='styles.a' mdxType="Link">https://linkedin.com/in/ipalongengi</Link>
    <Divider mdxType="Divider" />
    <Heading variant='styles.h3' sx={{
      mb: 0
    }} mdxType="Heading">GitHub</Heading>
    <Link href='https://github.com/ipalongengi' variant='styles.a' mdxType="Link">https://github.com/ipalongengi</Link>
    <Divider mdxType="Divider" />
    <Heading variant='styles.h3' sx={{
      mb: 0
    }} mdxType="Heading">Bitbucket</Heading>
    <Link href='https://bitbucket.org/ipalongengi' variant='styles.a' mdxType="Link">https://bitbucket.org/ipalongengi</Link>
    <Divider mdxType="Divider" />
    <Heading variant='styles.h3' sx={{
      mb: 0
    }} mdxType="Heading">Twitter</Heading>
    <Link href='https://twitter.com/ipalongengi' variant='styles.a' mdxType="Link">https://twitter.com/ipalongengi</Link>
    <Divider mdxType="Divider" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      